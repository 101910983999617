import { getRegionalisedBookingEngineUrl } from "./getRegionalisedBookingEngineUrl";

export const getBookingEngineFeedbackUrl = (
  localeCode: string,
  countryCode: string
): string => {
  const bookingEngineUrl = getRegionalisedBookingEngineUrl(
    localeCode,
    countryCode
  );
  return `${bookingEngineUrl}/feedback`;
};
