<template>
  <h1>Booking feedback redirect</h1>
</template>

<script lang="ts">
import Vue from "vue";
import { getBookingEngineFeedbackUrl } from "~/lib/utils/locale/getBookingEngineFeedbackUrl";
import { getCountryFromIncomingRequestOrDefault } from "~/lib/utils/locale/getCountryFromIncomingRequestOrDefault";

export default Vue.extend({
  middleware({ redirect, req, i18n }) {
    redirect(
      301,
      getBookingEngineFeedbackUrl(
        i18n.locale,
        getCountryFromIncomingRequestOrDefault(req)
      )
    );
  },
});
</script>
